import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icons from './Icons';
import BlankA from './BlankHyperlink';

const predefinedIcons = {
  trustedByCompaniesIcons: [{
    icon: 'ey-min'
  }, {
    icon: 'cbre-min'
  }, {
    icon: 'pizza-hut-min'
  }, {
    icon: 'burger-king-min'
  }, {
    icon: 'tilster-min'
  }, {
    icon: 'mari-min'
  }, {
    icon: 'smashcut-min'
  }, {
    icon: 'sigora-min'
  }, {
    icon: 'demco-min'
  }, {
    icon: 'dstoq-min'
  }, {
    icon: 'conference-compass-min'
  }, {
    icon: 'acer'
  }, {
    icon: 'siemens'
  }, {
    icon: 'bmw'
  }, {
    icon: 'massmutual'
  }, {
    icon: 'teachforward'
  }, {
    icon: 'illuminateed'
  }, {
    icon: 'gsk'
  }, {
    icon: 'apothera'
  }, {
    icon: 'docviser'
  }, {
    icon: 'livebuy'
  }, {
    icon: 'parking-match'
  }, {
    icon: 'stitch'
  }],
  clientIcons: [{
    icon: 'ey-min'
  }, {
    icon: 'cbre-min'
  }, {
    icon: 'pizza-hut-min'
  }, {
    icon: 'burger-king-min'
  }, {
    icon: 'tilster-min'
  }, {
    icon: 'mari-min'
  }, {
    icon: 'smashcut-min'
  }, {
    icon: 'sigora-min'
  }, {
    icon: 'demco-min'
  }, {
    icon: 'dstoq-min'
  }, {
    icon: 'conference-compass-min'
  }, {
    icon: 'acer'
  }, {
    icon: 'siemens'
  }, {
    icon: 'bmw'
  }, {
    icon: 'massmutual'
  }, {
    icon: 'teachforward'
  }, {
    icon: 'illuminateed'
  }, {
    icon: 'gsk'
  }, {
    icon: 'apothera'
  }, {
    icon: 'docviser'
  }, {
    icon: 'livebuy'
  }, {
    icon: 'parking-match'
  }, {
    icon: 'stitch'
  }],
  partnerIcons: [{
    icon: 'primoko-min',
    href: 'https://www.primoko.com/'
  }, {
    icon: 'mvp-min',
    href: 'https://mvpfactory.co/'
  }, {
    icon: 'goodfirms-min',
    href: 'https://www.goodfirms.co/'
  }]
};

const CompaniesIcons = ({ customIcons, iconsType }) => {
  const iconsItems = [];
  let icons;

  if (iconsType) {
    icons = predefinedIcons[iconsType];
  } else {
    icons = customIcons;
  }

  if (icons && icons.length) {
    icons.map(({ href, icon }) => {
      if (href) {
        return iconsItems.push(
          <BlankA key={icon} href={href} className={classnames('icon-layout', icon)}>
            <div className="label">{icon}</div>
          </BlankA>
        );
      }
      return iconsItems.push(<Icons key={icon} icon={icon} />);
    });
  }

  return (
    <div>
      {iconsType === 'partnerIcons'
        ? (
          <div className="icons-container-hp partners-no-wrap">
            {iconsItems}
          </div>
        ) : (
          <div className="icons-container-hp">
            {Array.isArray(icons) && icons.map(({ icon }, imageIdx) => (
              <div
                className={classnames('icon-layout', icon, 'icon-layout')}
                key={`icon_${(imageIdx + 1)}`}
                tabIndex="0"
              />
            ))}
          </div>
        )}
    </div>
  );
};

CompaniesIcons.propTypes = {
  customIcons: PropTypes.arrayOf(PropTypes.shape({})),
  iconsType: PropTypes.string
};

CompaniesIcons.defaultProps = {
  customIcons: [],
  iconsType: null
};

export default CompaniesIcons;
