import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DotSubtitle = (props) => {
  const { title, titleType, afterTitle, classNames, afterTitleClassNames, titleClassNames } = props;

  return (
    <div className={classnames('subsection-subtitle', classNames)}>
      <div className="main_inner our-container dot-title">
        {titleType === 'h1'
          ? (
            <h1 className={classnames('font-mega font-weight-bold', titleClassNames)} tabIndex="0">
              {title}
              <span className="dot-title__yellow ">.</span>
            </h1>
          )
          : (
            <h2 className={classnames('font-display font-weight-bold', titleClassNames)} tabIndex="0">
              {title}
              <span className="dot-title__yellow ">.</span>
            </h2>
          )
        }
      </div>

      {afterTitle
        ? (
          <div className={classnames(afterTitleClassNames)} tabIndex="0">
            {afterTitle}
          </div>
        )
        : null
            }
    </div>
  );
};

DotSubtitle.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.string,
  classNames: PropTypes.string,
  afterTitle: PropTypes.string,
  titleClassNames: PropTypes.string,
  afterTitleClassNames: PropTypes.string,
};

DotSubtitle.defaultProps = {
  title: null,
  titleType: null,
  classNames: null,
  afterTitle: null,
  titleClassNames: null,
  afterTitleClassNames: null,
};

export default DotSubtitle;
