import React, { Component } from "react";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import classnames from "classnames";
import { graphql, Link } from "gatsby";

import Image from "../assets/images/our-work-header-image.png";
import Layout from "../components/GatsbyLayout";
import data from "../data/our-work-data";
import CompaniesIcons from "../components/CompaniesIcons";
import Helmet from "../components/Helmet";
import Sidebar from "../components/Navigation/Sidebar";
import CaseStudyBanner from "../components/CaseStudyBanner";
import DotSubtitle from "../components/DotSubtitle";

const allCategories = [
  "Featured",
  "Financial",
  "Education",
  "Ecommerce",
  "Events",
  "Open Source",
  "Healthcare",
  "Real Estate"
];

class OurWork extends Component {
  componentDidMount() {
    this.simulateMouseClickOnTarget();
  }

  renderSubTitleText = id => {
    let subTitleText;

    switch (id) {
      case "SIGORA_SOLAR":
        subTitleText =
          "Product Strategy | Project Management | UI/UX Design | API Development | Software Development " +
          "| Devops & Infrastructure | QA & Automation";
        break;
      case "Kiosk_Tillster_Platform":
        subTitleText =
          "Software Development | Project/Product Management | QA & Automation | DevOps";
        break;
      case "Conference-Compass":
        subTitleText =
          "Software Development | Project Strategy | QA & Automation | DevOps & Infrastructure " +
          "| Business Analysis";
        break;
      case "DSTOQ_EXCHANGE":
        subTitleText =
          "Mobile Development | Product Management | Blockchain Development";
        break;
      case "Pie_Framework":
        subTitleText =
          "UI/UX Design | Product Management | Software Development ";
        break;
      case "Block_Bits_IO":
        subTitleText =
          "Product Strategy | Software Development | Blockchain Development";
        break;
      case "Kaito":
        subTitleText =
          "Product Strategy | UI/UX Design | Software Development | Business Analysis | QA & Automation";
        break;
      case "MARI_CHEF":
        subTitleText = "Product Strategy | Software Development";
        break;
      case "SMASHCUT":
        subTitleText =
          "Product Strategy | UI/UX Design | Software Development | QA & Automation";
        break;
      case "Demco_Software_Mobile":
        subTitleText =
          "Product Strategy | UI/UX Design | Project Management | Mobile Development | " +
          "Devops & Infrastructure | QA & Automation";
        break;
      case "Demco_Software_Web":
        subTitleText =
          "Product Strategy | UI/UX Design | Project Management | API Development | Web Development | " +
          "Devops & Infrastructure | QA & Automation";
        break;
      case "Delivery_Tillster_Platform":
        subTitleText =
          "Software Development | Project/Product Management | QA & Automation | DevOps";
        break;
      case "Quandora_Mobile_App":
        subTitleText =
          "Product Strategy | Software Development | QA & Automation";
        break;
      case "Geozone_Falcon":
        subTitleText =
          "Product Strategy | UI/UX Design | Project Management | API Development | Web Development | " +
          "Devops & Infrastructure | QA & Automation";
        break;
      case "squeezer":
        subTitleText =
          "Product Strategy | UI/UX Design | Project Management | API Development | Web Development | " +
          "Devops & Infrastructure | QA & Automation";
        break;
      case "Outcomes_for_Me":
        subTitleText = "Mobile Development | QA & Automation";
        break;
      case "Home_Match":
        subTitleText =
          "Product Strategy | UI/UX Design | Project Management | API Development | Software Development " +
          "| Devops & Infrastructure | QA & Automation";
        break;
      case "Docviser":
        subTitleText =
          "Product Strategy | UI/UX Design | Software Development | Business Analysis | QA & Automation " +
          "| DevOps & Infrastructure | Product Management";
        break;
      case "MYA":
        subTitleText =
          "Product Strategy | Software Development | DevOps & Infrastructure";
        break;
      case "Livebuy":
        subTitleText =
          "Product Strategy | Software Development | DevOps & Infrastructure ";
        break;
      case "Acer":
        subTitleText =
          "Product Strategy | Software Development | DevOps & Infrastructure";
        break;
      case "Parking Match":
        subTitleText =
          "Product Strategy | UI/UX Design | Software Development | Business Analysis | QA & Automation " +
          "| DevOps & Infrastructure | Product Management";
        break;
      case "Stitch":
        subTitleText =
          "Product Strategy | Software Development | QA & Automation | DevOps & Infrastructure ";
        break;
      case "Illuminate":
        subTitleText =
          "Product Strategy | Software Development | QA & Automation";
        break;
      case "TeachForward":
        subTitleText = "Software Development | DevOps & Infrastructure ";
        break;
      case "Herewith":
        subTitleText =
          "Software Development | Product Strategy | DevOps & Infrastructure";
        break;
      case "pga":
        subTitleText = "Mobile Development | QA";
        break;
      case "ap-news":
        subTitleText = "Software Development | Mobile Development";
        break;
      case "amplify":
        subTitleText = "API Development | Web Development";
        break;
      case "caretag":
        subTitleText = "Web Development | QA & Automation";
        break;
      default:
        subTitleText = "";
        break;
    }

    return subTitleText;
  };

  simulateMouseClickOnTarget = () => {
    if (window) {
      const hashElements = window.location.hash.split("#");
      if (!Array.isArray(hashElements) || hashElements.length < 1) {
        return;
      }

      const hashTarget = hashElements[1]
        ? hashElements[1].replace("%20", " ")
        : "";
      const matchTarget = document.getElementById(hashTarget);

      if (matchTarget && typeof matchTarget.click === "function") {
        setTimeout(() => {
          matchTarget.click();
        }, 500);
      }
    }
  };

  renderSection = ({ subtitle, content, contentClassNames }) => (
    <div className="main__section">
      {subtitle}
      <div className={classnames(contentClassNames)}>{content}</div>
    </div>
  );

  renderHelmet = () => (
    <Helmet
      title="Our Portfolio - MCRO Client Success Stories | Featured work"
      description="MCRO delivers amazing products for top companies all
      around the world, from startups to Fortune 500 companies."
      keywords="React, React Native, Angular, Ionic, iOS, Android, NodeJS,
      PHP, AWS, Devops, Security, QA, Automation, Blockchain"
      thumbnail={Image}
      canonical
      canonicalPage="our-work"
    />
  );

  renderCompanies = (title, afterTitle = "", Icons, iconsType) =>
    this.renderSection({
      subtitle: (
        <DotSubtitle
          title={title}
          titleClassNames="side-by-side"
          afterTitle={afterTitle}
          afterTitleClassNames="our-container after-title"
        />
      ),
      content: <Icons key={`${title}-icons`} iconsType={iconsType} />
    });

  renderCategoryProjects = categoryName => {
    const projects = data.filter(item =>
      item.categories.includes(categoryName)
    );

    return (
      <div key={categoryName} className="content">
        {projects.map(project => (
          <Link
            key={project.id}
            className="content-item"
            to={`/our-work/${project.linkId}/`}
          >
            <div className="content-item--image">
              <img
                src={project.cardImage}
                alt={project.title}
                title={project.title}
              />
            </div>
            <img
              src={project.logo}
              alt={`${project.title} Logo`}
              title={`${project.title} Logo`}
              className="content-item--logo"
            />
            <h2 className="content-item--title" tabIndex="0">
              {project.cardDescription}
            </h2>
            <h3 className="content-item--subtitle" tabIndex="0">
              {this.renderSubTitleText(project.id)}
            </h3>
          </Link>
        ))}
      </div>
    );
  };

  renderCategory = title => (
    <div
      className="scroll-page__category"
      key={title.toUpperCase()}
      id={title.toUpperCase()}
    >
      <div className="scroll-page__category-name" tabIndex="0">
        {title}
      </div>
      {this.renderCategoryProjects(title)}
    </div>
  );

  renderContent = () => (
    <div className="projects-container">
      <Sidebar all={allCategories} />
      <div className="projects-container scroll-page">
        {allCategories.map(category => this.renderCategory(category))}
      </div>
    </div>
  );

  render() {
    const { location, data: gatsbyData } = this.props;
    const fluidSrc = gatsbyData.file.childImageSharp.fluid;
    return (
      <Layout location={location}>
        {this.renderHelmet()}
        <div id="our-work" className="main main__inner">
          <CaseStudyBanner
            category="PORTOFOLIO"
            title="Our work"
            description="Here's some of our mobile app, custom software & strategy work
             across a variety of technologies and industries."
            fluid={fluidSrc}
            buttonText="Get a quote"
            onButtonPress="/contact/"
          />
          {this.renderContent()}
        </div>
        <h1 className="show-for-seo">
          High performant web and mobile apps for top fortune 500 companies and
          startups.
        </h1>
        <div className="our-container-wrapper">
          <div className="main main__inner our-container">
            {this.renderCompanies(
              "Our clients",
              "Enterprises from all around the world trust us to develop" +
                " and launch their digital products.",
              CompaniesIcons,
              "clientIcons"
            )}
            {this.renderCompanies(
              "Our partners",
              "Collaboration is key for innovation, which is why " +
                "our business thrives on great relationships with our business partners.",
              CompaniesIcons,
              "partnerIcons"
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

OurWork.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

OurWork.defaultProps = {
  data: null
};

export default OurWork;

export const query = graphql`
  query indexPage {
    file(relativePath: { eq: "our-work-header-image.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 550) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
