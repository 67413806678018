import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Icons = ({ icon, className }) => {
  const classesIcon = classnames('icon-layout', icon, className);

  return (
    <div className={classesIcon} />
  );
};

Icons.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string
};

Icons.defaultProps = {
  icon: '',
  className: ''
};

export default Icons;
